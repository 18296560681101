<div class="row text-info">
  <div class="col-10 mx-auto text-center text-danger">
    <h1 innerHTML={{eAppMessage}}></h1>
  </div>
</div>
<ng-container *ngIf="!isAnnuity">
  <div class="container w-100 vh-100">
    <div class="row">
      <div class="col-md-12 bg-applicationform">
      </div>
    </div>
    <div class="row" style="margin-top:6px;color:#fff">
      <div class="col-6 text-center" style="background-color: #6564ad; padding-top: 20px;border-right: 6px solid white;">
        <h1><b>New Business E-Application System</b></h1>
      </div>
      <div class="col-6 text-center" style="background-color: #6564ad;padding-top: 20px;">
        <h1><b>Demo the E-App in "Training" Mode</b></h1>
      </div>
    </div>
    <div class="row" style="color:#fff">
      <div class="col-6 mx-auto" style="background-color: #6564ad;border-right: 6px solid white">
        <div class="col-8 mx-auto" style="padding-top: 5px;padding-bottom:10px;"><p>We're making it easier for you and your clients to submit an electronic application! With our new E-App, you are able to use quick fill-and-sign features, can work on it remotely or in-person and eliminate applications that are not in good order.</p></div>
      </div>
      <div class="col-6 mx-auto" style="background-color: #6564ad;">
        <div class="col-8 mx-auto" style="padding-top: 5px;padding-bottom:10px;"><p>First time using the E-App? Use the Training mode to try out a test case and make sure you are comfortable before working with your client.</p></div>
      </div>
    </div>
    <div *ngIf="!isWebHookConnection; else webHookConnection">
      <div class="row" style="background-color: #6564ad">
        <div class="col-6 mx-auto" style="border-right: 6px solid white">
          <div class="col-8 mx-auto" style="padding-bottom: 30px;">
            <button class="btn btn-secondary btn-outline-light btn-block" (click)="login(true)" [disabled]="!eAppEnabled">Launch E-App</button>
          </div>
        </div>
        <div class="col-6 mx-auto">
          <div class="col-8 mx-auto" style="padding-bottom: 30px;">
            <button class="btn btn-secondary btn-outline-light btn-block" (click)="login(false)" [disabled]="!eAppEnabled">Training E-App</button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #webHookConnection>
      <div class="row" style="background-color: #6564ad">
        <div class="col-6 mx-auto" style="border-right: 6px solid white">
          <div class="col-8 mx-auto" style="padding-bottom: 30px;">
            <button class="btn btn-secondary btn-outline-light btn-block" (click)="goToApp(true)" [disabled]="!eAppEnabled">Launch E-App</button>
          </div>
        </div>
        <div class="col-6 mx-auto">
          <div class="col-8 mx-auto" style="padding-bottom: 30px;">
            <button class="btn btn-secondary btn-outline-light btn-block" (click)="goToApp(false)" [disabled]="!eAppEnabled">Training E-App</button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isSuppHealth">
        <div class="row" style="margin-top:6px;color:#fff">
          <div class="col-6 text-center" style="background-color: #6564ad; padding-top: 20px;border-right: 6px solid white;">
            <h1><b>Resume Pended New Business Application</b></h1>
          </div>
          <div class="col-6 text-center" style="background-color: #6564ad;padding-top: 20px;">
            <h1><b>View Recently Submitted Applications<br />(Submitted in the Past 30 Days)</b></h1>
          </div>
        </div>
        <div class="row" style="color:#fff">
          <div class="col-6 mx-auto" style="background-color: #6564ad;border-right: 6px solid white">
            <div class="col-8 mx-auto" style="padding-top: 5px;padding-bottom:10px;">
              <p>If you started an E-Application but did not finish, click here to resume and select your case from the list to continue.</p>
            </div>
          </div>
          <div class="col-6 mx-auto" style="background-color: #6564ad;">
            <div class="col-8 mx-auto" style="padding-top: 5px;padding-bottom:10px;">
              <p>
                If you have questions regarding information submitted on a recently completed application, click here. Please note changes cannot
                be made to completed applications, this feature is for reference only.
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="background-color: #6564ad">
          <div class="col-6 mx-auto" style="border-right: 6px solid white">
            <div class="col-3 mx-auto" style="padding-bottom: 30px;">
              <button class="btn btn-secondary btn-outline-light btn-block" (click)="returnToApp()" [disabled]="!eAppEnabled">Resume</button>
            </div>
          </div>
          <div class="col-6 mx-auto">
            <div class="col-3 mx-auto" style="padding-bottom: 30px;">
              <button class="btn btn-secondary btn-outline-light btn-block" (click)="viewCompletedApp()" [disabled]="!eAppEnabled">Completed</button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="isAnnuity">
  <div class="container w-100">
    <div class="row mt-2">
      <div class="col-md-6">
        <form #illustrationForm="ngForm" (ngSubmit)="openIllustration()">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">
                <span>Quote</span>
              </h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8 mx-auto" style="padding-top: 5px;padding-bottom:10px;"><p>Select to use our comprehensive system to create quotes for Deferred, Fixed Indexed and SPIA annuities.</p></div>
              </div>
              <div class="row">
                <div class="form-group col-8 mx-auto">
                  <label>Product</label>
                  <select name="product" id="product" class="form-control" (change)="onProductChange(illustrationForm.controls.product)" [(ngModel)]="product">
                    <option value="">Select Product</option>
                    <option value='spiaIllustrations.php'>Single Premium Immediate Annuity (SPIA)</option>
                    <option value='deferredIllustrations.php'>Deferred Annuity (MYGA)</option>
                    <option value='fiaIllustrations.php'>Fixed Indexed Annuity (FIA)</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-8 mx-auto">
                  <label>Company</label>
                  <select name="company" id="company" class="form-control" (change)="onCompanyChange(illustrationForm.controls.company)" [(ngModel)]="company">
                    <option value="0">Select Company</option>
                    <option [value]="company.value" *ngFor="let company of companyList">
                      {{company.text}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-8 mx-auto" style="padding-bottom: 30px;">
                  <input type="submit" class="btn btn-secondary btn-block" name="illustrateButton" id="illustrateButton" value="Illustrate" [disabled]="disableIllustrate" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-6">
        <form #AnnuityEAppForm action="{{annuityEAppURL}}" method="post" target="_blank">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">
                <span>Apply</span>
              </h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-9 mx-auto text-center" style="padding-top: 5px;padding-bottom:10px;"><p>Select to submit an E-App for Deferred and FIA Annuities.</p></div>
              </div>
              <div class="row">
                <div class="col-8 mx-auto" style="padding-bottom: 30px;">
                  <input type="hidden" name="SAMLResponse" [(ngModel)]="samlResponse">
                  <input type="hidden" name="RelayState" value="Home">
                  <button class="btn btn-secondary btn-block" type="submit" (click)="openAnnuityEApp()" [disabled]="!eAppEnabled">Launch E-App</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
