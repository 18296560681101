import { AfterViewInit, Component, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import { ReturnDateRangeFromType, DateComparator } from '../../../common/helpers';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';
import { Profile } from '../../../models/profile';
import { KPI } from '../../../models/kpi';
import { AgentLink } from '../../../models/agentLink';
import { Policy } from '../../../models/policy';
import { Subscription } from 'rxjs';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { AgentGrid } from '../../../models/agentGrid';
import { CloudStorageService } from '../../../services/cloud-storage';
import { map, take } from 'rxjs/operators';
import { gridColumn } from '../../../models/gridColumn';
import { CellClickedEvent } from 'ag-grid';
import { currencyFormatter } from '../../../common/ag-grid-currencyFormatter';

@Component({
  selector: 'app-policyinfo',
  templateUrl: './policyinfo.component.html',
  styleUrls: ['./policyinfo.component.css']
})
export class PolicyInfoComponent implements OnInit, AfterViewInit {
  public showSpinner = false;
  public showSearch: boolean = false;
  public showSpinner2 = false;
  public showDetail: boolean = false;
  public searchType: string = "1";
  public showReset: boolean = false;
  public showExport: boolean = false;
  public isAnnuityPolicy!: boolean;
  public detailTitleName: string = "";
  public kpis: KPI[] = [];
  public agents: AgentGrid[] = [];
  public downlineAgents: AgentLink[] = [];
  public searchRowData: Policy[] = [];
  public coverageRowData: [] = [];
  public paymentRowData: [] = [];
  public withdrawalRowData: [] = [];
  public statusHistoryRowData: [] = [];
  public underwritingStatusRowData: [] = [];
  public allocationRowData: [] = [];
  public hierarchyRowData: [] = [];
  public beneficiaryInfoRowData: [] = [];
  public beneficiaryRowData: [] = [];
  public documentsRowData: [] = [];
  public searchRowCount: number = 0;
  public profile: Profile = <Profile>{};
  private channelSubscription: Subscription;
  private impersonationSubscription: Subscription;
  private kpiSearchSubscription: Subscription;
  public policyStatus = "Inforce";
  public timeFrame: string = "ALL";
  private currentDate = new Date();
  public startDate: Date = new Date("");
  public endDate: Date = new Date("");
  public timeFrameStart: Date = new Date("");
  public timeFrameEnd: Date = new Date("");
  public includedAgents: number = 3;
  public insuredName: string = "";
  public phone: string = "";
  public addressCity: string = "";
  public agent: string = "ANY";
  public downlineAgent: string = "ANY";
  public policy: string = "";
  public kpi: number = 0;
  public selectedPolicy: Policy = <Policy>{};
  private gridApi: any;
  private gridApi1: any;
  private gridApi2: any;
  private gridApi3: any;
  private gridApi4: any;
  private gridApi5: any;
  private gridApi6: any;
  private gridApi7: any;
  private gridApi8: any;
  private gridApi9: any;
  private gridColumnApi: any;
  private gridColumnApi1: any;
  private gridColumnApi2: any;
  private gridColumnApi3: any;
  private gridColumnApi4: any;
  private gridColumnApi5: any;
  private gridColumnApi6: any;
  private gridColumnApi7: any;
  private gridColumnApi8: any;
  private gridColumnApi9: any;
  private gridName: string = "Policy";
  public noRowsTemplate: string = "";
  public asOfDateMessage: string = "";
  public limitMessage: string = "";
  public disableTimeFrame: boolean = true;
  public disableAdvancedDates: boolean = true;
  public clientInfoAccordionExpanded: boolean = false;
  public rmaAccordionExpanded: boolean = false;
  public allocationAccordionExpanded: boolean = false;
  searchColumnDefs!: ColDef[];
  coverageColumnDefs = [
    { field: 'coverageType', headerName: 'Coverage', minWidth: 125, maxWidth: 125 },
    { field: 'coveragePlanCode', headerName: 'Plan Code', minWidth: 125, maxWidth: 125 },
    { field: 'coveragePlanDesc', headerName: 'Plan Description', minWidth: 300 },
    { field: 'coveredPerson', headerName: 'Insured', minWidth: 100 },
    { field: 'ageAtIssue', headerName: 'Issue Age', minWidth: 100, maxWidth: 125 },
    { field: 'benefitAmt', headerName: 'Benefit Amount', minWidth: 100, maxWidth: 150 },
    { field: 'expiryDate', headerName: 'Expires', minWidth: 100, maxWidth: 125, comparator: DateComparator },
    { field: 'payUpDate', headerName: 'Paid To', minWidth: 100, maxWidth: 125, comparator: DateComparator }
  ];
  paymentColumnDefs = [
    { field: 'paymentDate', headerName: 'Payment Date', minWidth: 100, maxWidth: 125, comparator: DateComparator },
    { field: 'postDate', headerName: 'Post Date', minWidth: 100, maxWidth: 125, comparator: DateComparator },
    { field: 'premiumAmt', headerName: 'Premium', minWidth: 100, maxWidth: 125 },
    { field: 'source', headerName: 'Source', minWidth: 100}
  ];
  withdrawalColumnDefs = [
    { field: 'withdrawalDate', headerName: 'W/D Date', minWidth: 100, maxWidth: 125, comparator: DateComparator },
    { field: 'grossAmount', headerName: 'Gross W/D', minWidth: 100, maxWidth: 125, comparator: DateComparator },
    { field: 'surrenderCharge', headerName: 'Surr Charge', minWidth: 100, maxWidth: 125 },
    { field: 'mva', headerName: 'MVA', minWidth: 100, maxWidth: 125 },
    { field: 'fedTaxWithheld', headerName: 'Fed WH', minWidth: 100, maxWidth: 125 },
    { field: 'stateTaxWithheld', headerName: 'State WH', minWidth: 100, maxWidth: 125 },
    { field: 'netAmount', headerName: 'Net Amount', minWidth: 100, maxWidth: 125 }
  ];
  statusHistoryColumnDefs = [
    { field: 'status', headerName: 'Status', minWidth: 100 },
    { field: 'date', headerName: 'Status Date', minWidth: 100, comparator: DateComparator },
  ];
  underwritingStatusColumnDefs = [
    { field: 'underwritingItem', headerName: 'Underwriting Item', minWidth: 100 },
    { field: 'code', headerName: 'Code', minWidth: 100 },
    { field: 'requestedDate', headerName: 'Requested Date', minWidth: 100, comparator: DateComparator },
    { field: 'resolvedDate', headerName: 'Resolved Date', minWidth: 100, comparator: DateComparator }
  ];
  hierarchyColumnDefs = [
    { field: 'agentID', headerName: 'Agent ID', minWidth: 100, maxWidth: 125 },
    { field: 'agentName', headerName: 'Agent Name', minWidth: 275 },
    { field: 'level', headerName: 'Level', minWidth: 100, maxWidth: 125 },
    { field: 'commissionPct', headerName: 'Commission %', minWidth: 125, maxWidth: 150 },
    { field: 'agentType', headerName: 'Agent Type', minWidth: 100 }
  ];
  beneficiaryColumnDefs = [
    { field: 'name', headerName: 'Name', minWidth: 300 },
    { field: 'relationship', headerName: 'Relationship', minWidth: 175 },
    { field: 'split', headerName: 'Split %', minWidth: 125, maxWidth: 100 },
    { field: 'type', headerName: 'Type', minWidth: 175 }
  ];
  allocationColumnDefs = [
    { field: 'policyAllocationText', headerName: 'Strategy', minWidth: 300 },
    { field: 'allocationPct', headerName: 'Percentage', minWidth: 100 },
    { field: 'lastAnniversaryValue', headerName: 'Last Anniversary Value', minWidth: 200 },
    { field: 'currentValue', headerName: 'Current Value', minWidth: 200 }
  ];
  documentsColumnDefs = [
    { field: 'fileName', headerName: 'Document Name', minWidth: 275 },
    { field: 'fileType', headerName: 'Document Type', minWidth: 100 },
    { field: 'fileDate', headerName: 'Document Date', minWidth: 100, comparator: DateComparator },
    {
      headerName: '  Download', maxWidth: 125, cellStyle: { textAlign: 'center' },
      onCellClicked: (event: CellClickedEvent) => this.downloadDocument(event.data),
      cellRenderer: (params: any) => { return '<i class="fa fa-download"></i>'; }
    },
  ];
  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(public cloudStorageService: CloudStorageService, private onPremService: OnPremStorageService, private siteParmService: SiteParameterService,
    private datePipe: DatePipe) { //<i class="fa-duotone fa-magnifying-glass-dollar"></i>
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    this.channelSubscription = this.siteParmService.getChannelUpdate().subscribe
      (message => {
        this.getKPIList();
        this.getAgentList();
        this.getDownlineAgentList(this.profile.selectedAgentNumber);
      });
    this.impersonationSubscription = this.siteParmService.getImpersonationUpdate().subscribe
      (message => {
        this.profile = siteParmService.getStaticProfile();
        this.getKPIList();
        this.getAgentList();
        this.getDownlineAgentList(this.profile.selectedAgentNumber);
      });
    this.kpiSearchSubscription = this.siteParmService.getkpiSearchUpdate().subscribe
      (message => {
        if (this.kpis.length > 0) {
          $('#submenu').find("*").removeClass("router-link-exact-active");
          $('#KPI-tab').addClass('router-link-exact-active');
          $('#QuickSearch').removeClass('active');
          $('#AdvancedSearch').removeClass('active');
          $('#KPI').addClass('active');
          this.kpi = this.kpis.filter(k => k.description === message.value)[0].kpiid;
          this.includedAgents = 3;
          this.searchType = "3";
          var element = document.getElementById('endOfPage') as HTMLElement;
          this.searchPolicies(element);
        }
      });
  }
  ngOnInit(): void {
    this.profile = this.siteParmService.getStaticProfile();
    this.getKPIList();
    this.getAgentList();
    this.getDownlineAgentList(this.profile.selectedAgentNumber);
    this.setDetailType();
  }
  ngAfterViewInit(): void {
    $('#submenu li a').click(function () {
      $('#submenu').find("*").removeClass("router-link-exact-active");
      $(this).addClass('router-link-exact-active');
    });
    $('#detailMenu li a').click(function () {
      $('#detailMenu').find("*").removeClass("router-link-exact-active");
      $(this).addClass('router-link-exact-active');
    });
  }
  getColumnDefs(): void {
    const channel: number = this.profile.channelPreferenceValue;
    this.onPremService.getGridColumnList(channel, this.gridName)
      .pipe(map(resp => {
        return resp.data.map((column: gridColumn) => {
          return {
            field: column["field"], headerName: column["header"], minWidth: column["minWidth"], comparator: column["field"].toLowerCase().includes("date") ? DateComparator : '',
            valueFormatter: column["valueType"].toLowerCase().match("money") ? currencyFormatter : '' };
        });
      }))
      .pipe(take(1))
      .subscribe(list => {
        this.searchColumnDefs = list;
      });
  }
  changeTimeFrame(e: any) {
    this.changeTimeFrameDates(e.target.value);
  }
  changeTimeFrameDates(timeFrame: string) {
    let [start, end] = ReturnDateRangeFromType(this.timeFrame);
    this.timeFrameStart = start!;
    this.timeFrameEnd = end!;
  }
  onStartDateSelect(e: Event) {
    this.startDate = (e.target as HTMLInputElement).valueAsDate!;
    if (this.startDate! > this.endDate!) {
      this.endDate = new Date(this.startDate);
      this.endDate.setDate(this.endDate.getDate() + 7);
    }
  }
  onEndDateSelect(e: Event) {
    this.endDate = (e.target as HTMLInputElement).valueAsDate!;
    if (this.startDate! > this.endDate!) {
      this.endDate = new Date(this.startDate);
      this.endDate.setDate(this.endDate.getDate() + 7);
    }
  }
  searchPolicies(element: HTMLElement): void {
    this.showSearch = true;
    this.showSpinner = true;
    this.showDetail = false;
    this.searchRowData = [];
    this.searchRowCount = 0;
    this.asOfDateMessage = "";
    this.selectedPolicy = <Policy>{};
    this.profile = this.siteParmService.getStaticProfile();
    this.getColumnDefs();
    switch (this.searchType) {
      case "1":
        let [start, end] = ReturnDateRangeFromType(this.timeFrame);
        this.startDate = start!;
        this.endDate = end!;
        const startDateString = (this.startDate != null ? this.datePipe.transform(this.startDate, 'MM/dd/yyyy') : "") as string;
        const endDateString = (this.endDate != null ? this.datePipe.transform(this.endDate, 'MM/dd/yyyy') : "") as string;

        this.onPremService.searchPolicies(this.profile.selectedAgentNumber, this.policyStatus, startDateString, endDateString,
          this.includedAgents, this.profile.channelPreference)
          .subscribe(response => {
            this.searchRowData = response.policies;
            this.searchRowCount = response.policies.length;
            this.showExport = this.searchRowCount > 0 ? true : false;
            this.asOfDateMessage = response.asOfDateMessage;
            this.limitMessage = response.limitMessage;
            this.showSpinner = false;
            element.scrollIntoView();
          });
        break;
      case "2":
        let parsedStartDate = '';
        let parsedEndDate = '';

        if (!isNaN(this.startDate.getDay())) {
          const startSearchDate = new Date(this.startDate.getUTCFullYear(), this.startDate.getUTCMonth(), this.startDate.getUTCDate());
          parsedStartDate = this.datePipe.transform(startSearchDate, 'MM/dd/yyyy') as string;
        }
        if (!isNaN(this.endDate.getDay())) {
          const endSearchDate = new Date(this.endDate.getUTCFullYear(), this.endDate.getUTCMonth(), this.endDate.getUTCDate());
          parsedEndDate = this.datePipe.transform(endSearchDate, 'MM/dd/yyyy') as string;
        }

        this.onPremService.searchAdvancedPolicies(this.profile.selectedAgentNumber, this.agent, this.downlineAgent, this.policyStatus, parsedStartDate,
          parsedEndDate, this.insuredName, this.addressCity, this.phone, this.policy, this.profile.channelPreference)
          .subscribe(response => {
            this.searchRowData = response.policies;
            this.searchRowCount = response.policies.length;
            this.showExport = this.searchRowCount > 0 ? true : false;
            this.asOfDateMessage = response.asOfDateMessage;
            this.limitMessage = response.limitMessage;
            this.showSpinner = false;
            element.scrollIntoView();
          });
        break;
      case "3":
        this.onPremService.searchKpiPolicies(this.profile.selectedAgentNumber, this.kpi, this.includedAgents, this.profile.channelPreference)
          .subscribe(response => {
            this.searchRowData = response.policies;
            this.searchRowCount = response.policies.length;
            this.showExport = this.searchRowCount > 0 ? true : false;
            this.asOfDateMessage = response.asOfDateMessage;
            this.limitMessage = response.limitMessage;
            this.showSpinner = false;
            element.scrollIntoView();
          });
        break;
    };
  }
  clearSearch(searchType: string): void {
    this.showReset = false;
    if (searchType === '2') {
      this.showReset = true;
      this.policyStatus = 'Any';
      this.startDate = new Date("");
      this.endDate = new Date("");
      this.disableAdvancedDates = true;
      $('#AdvancedSearch').addClass('active');
      $('#QuickSearch').removeClass('active');
      $('#KPI').removeClass('active');
    }
    if (searchType === '1') {
      this.policyStatus = 'Inforce';
      this.timeFrame = "ALL"
      this.changeTimeFrameDates("ALL");
      this.disableTimeFrame = true;
      $('#QuickSearch').addClass('active');
      $('#AdvancedSearch').removeClass('active');
      $('#KPI').removeClass('active');
    }
    this.searchType = searchType;
    this.searchRowData = [];
    this.showDetail = false;
    this.showSearch = false;
  }
  resetAdvancedSearch(): void {
    this.startDate = new Date("");
    this.endDate = new Date("");
    this.agent = 'ANY';
    this.downlineAgent = 'ANY';
    this.policy = '';
    this.insuredName = '';
    this.addressCity = '';
    this.phone = '';
    this.policyStatus = 'Any';
    this.disableAdvancedDates = true;
    this.limitMessage = '';
    this.searchRowData = [];
    this.searchRowCount = 0;
  }
  checkDates(): void {
    this.disableTimeFrame = false;
    if (this.policyStatus === 'Inforce' || this.policyStatus === 'Pending' || this.policyStatus === 'Past Due') {
      this.timeFrame = "ALL"
      this.changeTimeFrameDates(this.timeFrame);
      this.disableTimeFrame = true;
    }
  }
  checkAdvancedDates(): void {
    this.disableAdvancedDates = false;
    if (this.policyStatus === 'Any' || this.policyStatus === 'Inforce' || this.policyStatus === 'Pending' || this.policyStatus === 'Past Due') {
      this.startDate = new Date("");
      this.endDate = new Date("");
      this.disableAdvancedDates = true;
    }
  }
  getKPIList(): void {
    this.onPremService.getKpiList(this.profile.selectedAgentNumber, true)
      .subscribe(kpis => {
        this.kpis = kpis;
        this.kpi = kpis[0].kpiid;
      });
  }
  getAgentList(): void {
    this.cloudStorageService.getLinkedAgents(this.profile.auth0Id)
      .subscribe(agents => {
        this.agents = agents;
      });
  }
  getDownlineAgentList(agentId: string): void {
    if (this.agent === 'ANY')
      agentId = this.profile.selectedAgentNumber;

    this.onPremService.getAgentHierarchyList(agentId)
      .subscribe(apiReponse => {
        this.downlineAgents = apiReponse.data;
      });
  }
  setDetailType(): void {
    if (this.profile.channelPreference == 'Annuity' || this.profile.channelPreference == 'Annuity[Bank]') {
      this.isAnnuityPolicy = true;
    } else {
      this.isAnnuityPolicy = false;
    }
  }
  filterFunction(agents: AgentGrid[]): any[] {
    return agents.filter(i => i.channelId === this.profile.channelPreferenceValue);
  }
  onRowClicked(event: RowSelectedEvent) {
    this.selectedPolicy = <Policy>{};
    this.onPremService.getPolicyDetail(this.profile.selectedAgentNumber, event.data.policyNumber, event.data.regionID, this.profile.channelPreferenceValue)
      .pipe(take(1))
      .subscribe(policyInfo => {
        this.selectedPolicy = policyInfo.data[0];
        this.coverageRowData = policyInfo.data[0].policyCoverages;
        this.paymentRowData = policyInfo.data[0].policyPayments;
        this.withdrawalRowData = policyInfo.data[0].policyWithdrawals;
        this.statusHistoryRowData = policyInfo.data[0].policyStatusHistory;
        this.underwritingStatusRowData = policyInfo.data[0].policyUnderwritingStatus;
        this.hierarchyRowData = policyInfo.data[0].policyHierarchy;
        this.beneficiaryInfoRowData = policyInfo.data[0].policyBeneficiaryInfo;
        this.beneficiaryRowData = policyInfo.data[0].policyBeneficiary;
        this.allocationRowData = policyInfo.data[0].policyAllocation;
        this.documentsRowData = policyInfo.data[0].policyDocuments;
        if (policyInfo.data[0].policyCoverages == null) {
          $('#Coverages-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyPayments == null) {
          $('#PremiumHistory-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyWithdrawals == null) {
          $('#WithdrawalHistory-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyStatusHistory == null) {
          $('#StatusHistory-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyUnderwritingStatus == null) {
          $('#Underwriting-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].policyHierarchy == null) {
          $('#Hierarchy-tab').addClass('policymenudisabled');
        }
        if (policyInfo.data[0].annuitantName == '') {
          this.detailTitleName = policyInfo.data[0].insuredName
        } else {
          this.detailTitleName = policyInfo.data[0].annuitantName
        }
        if (policyInfo.data[0].policyDocuments == null) {
          $('#Documents-tab').addClass('policymenudisabled');
        }
      });
    this.showSearch = false;
    this.showDetail = true;
  }
  displayDetail(detailPageName: string): boolean {
    var show: boolean = false;
    switch (this.profile.channelPreference) {
      case 'Annuity': {
        if (detailPageName === 'Annuity') {
          show = true;
        }
        else {
          show = false;
        }
        break;
      }
      case 'Annuity [Bank]': {
        if (detailPageName === 'Annuity') {
          show = true;
        }
        else {
          show = false;
        }
        break;
      }
      default: {
        if (detailPageName === 'Annuity') {
          show = false;
        }
        else {
          show = true;
        }
      }
    };
    return show;
  }
  toggleClientInfoAccordion() {
    this.clientInfoAccordionExpanded = !this.clientInfoAccordionExpanded;
  }
  getClientInfoAccordionArrowStyle() {
    return this.clientInfoAccordionExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down';
  }
  toggleRmaAccordion() {
    this.rmaAccordionExpanded = !this.rmaAccordionExpanded;
  }
  getRmaAccordionArrowStyle() {
    return this.rmaAccordionExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down';
  }
  toggleAllocationAccordion() {
    this.allocationAccordionExpanded = !this.allocationAccordionExpanded;
  }
  getAllocationAccordionArrowStyle() {
    return this.allocationAccordionExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down';
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
  }
  onGridReady1(params: any) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    //this.gridApi1.sizeColumnsToFit();
  }
  onGridReady2(params: any) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    //this.gridApi2.sizeColumnsToFit();
  }
  onGridReady3(params: any) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
    //this.gridApi3.sizeColumnsToFit();
  }
  onGridReady4(params: any) {
    this.gridApi4 = params.api;
    this.gridColumnApi4 = params.columnApi;
    //this.gridApi4.sizeColumnsToFit();
  }
  onGridReady5(params: any) {
    this.gridApi5 = params.api;
    this.gridColumnApi5 = params.columnApi;
    //this.gridApi5.sizeColumnsToFit();
  }
  onGridReady6(params: any) {
    this.gridApi6 = params.api;
    this.gridColumnApi6 = params.columnApi;
    //this.gridApi6.sizeColumnsToFit();
  }
  onGridReady7(params: any) {
    this.gridApi7 = params.api;
    this.gridColumnApi7 = params.columnApi;
    //this.gridApi6.sizeColumnsToFit();
  }
  onGridReady8(params: any) {
    this.gridApi8 = params.api;
    this.gridColumnApi8 = params.columnApi;
    //this.gridApi6.sizeColumnsToFit();
  }
  onGridReady9(params: any) {
    this.gridApi9 = params.api;
    this.gridColumnApi9 = params.columnApi;
    //this.gridApi6.sizeColumnsToFit();
  }
  displayList(list: string): boolean {
    var show: boolean = false;
    switch (this.profile.channelPreference) {
      case 'Annuity': {
        if (list === 'Underwriting' || list === 'BeneficiaryInfo' || list === 'Documents' || list === 'Withdrawal') {
          show = true;
        }
        break;
      }
      case 'Annuity [Bank]': {
        if (list === 'Underwriting' || list === 'BeneficiaryInfo' || list === 'Documents' || list === 'Withdrawal') {
          show = true;
        }
        break;
      }
      case 'Pre-Need': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Home Service': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      case 'Home Service [Career]': {
        if (list === 'BeneficiaryInfo') {
          show = true;
        }
        break;
      }
      default: {
        show = false;
      }
    };
    return show;
  }
  closeDetail() {
    this.clientInfoAccordionExpanded = false;
    this.rmaAccordionExpanded = false;
    this.showDetail = false;
    this.showSearch = true;
  }
  printPage() {
    if (this.profile.channelPreference === 'Annuity' || this.profile.channelPreference === 'Annuity [Bank]') {
      $('#annuityClientInfoCollapse').addClass("show");
      window.scrollTo(0, 0);
      window.print();
      $('#annuityClientInfoCollapse').removeClass("show");
    } else {
      window.scrollTo(0, 0);
      window.print();
    }
  }
  addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  detailTabView(link: string) {
    $('#detailMenu').find("*").removeClass("router-link-exact-active");
    $(link).addClass('router-link-exact-active');
    //this.gridApi1.sizeColumnsToFit();
    //this.gridApi2.sizeColumnsToFit();
    //this.gridApi3.sizeColumnsToFit();
    //this.gridApi4.sizeColumnsToFit();
    //this.gridApi5.sizeColumnsToFit();
  }
  exportToExcel() {
    const params = {
      fileName: this.profile.selectedAgentNumber + '_Policies',
      sheetName: 'Policies'
    };
    this.gridApi.exportDataAsExcel(params);
  }
  downloadDocument(data: any) {
    const policyNumber = data.policyNumber;
    const documentDate = data.fileDate.replaceAll('/', '').trim();
    this.onPremService.downloadDocument(data.policyDocumentID)
      .pipe(take(1))
      .subscribe(data => {
        const binaryData = [];
        binaryData.push(data);
        const downloadedFile = new Blob(binaryData);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = "LBIG" + "_" + policyNumber + "_" + documentDate + ".pdf";
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.channelSubscription.unsubscribe();
    this.impersonationSubscription.unsubscribe()
    this.kpiSearchSubscription.unsubscribe();
  }
}
