import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ColSpanParams, ColDef } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { channelProduct } from '../../../models/channelProduct';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-annuityrates',
  templateUrl: './annuityrates.component.html',
})

export class AnnuityRatesComponent implements OnInit {

  public currentDate = new Date();
  public channelId = 1;
  public screenWidthForLargeDevice: number = 992;
  public screenWidthForSmallDevice: number = 576;
  public channelProduct!: channelProduct;
  public newBusinessDeferredRatesRowData: [] = [];
  public newBusinessImmediateRatesRowData: [] = [];
  public newBusinessFixedIndexRatesRowData: [] = [];
  public renewalDeferredRatesRowData: [] = [];
  public renewalImmediateRatesRowData: [] = [];
  public newBusinessCADeferredRatesRowData: [] = [];
  public newBusinessCAImmediateRatesRowData: [] = [];
  public newBusinessCAFixedIndexRatesRowData: [] = [];
  private gridApi: any;
  private gridApi1: any;
  private gridApi2: any;
  private gridApi3: any;
  private gridApi4: any;
  private gridApi5: any;
  private gridApi6: any;
  private gridApi7: any;
  private gridColumnApi: any;
  private gridColumnApi1: any;
  private gridColumnApi2: any;
  private gridColumnApi3: any;
  private gridColumnApi4: any;
  private gridColumnApi5: any;
  private gridColumnApi6: any;
  private gridColumnApi7: any;
  public noRowsTemplate: string = "";
  public groupDefaultExpanded = 1;
  public domLayout = "autoHeight";
  public innerWidth: any;

  ratesDeferredNBRatesColumnDefs!: ColDef[];
  ratesDeferredRenewalRatesColumnDefs!: ColDef[];
  ratesImmediateNBRatesColumnDefs!: ColDef[];
  ratesImmediateRenewalRatesColumnDefs!: ColDef[];
  fixedIndexRatesColumnDefs!: ColDef[];

  defaultColumnDef = {
    sortable: true,
    suppressMenu: true,
    suppressMovable: true
  };

  public autoGroupColumnDef: ColDef = {
    headerName: '',
    cellRendererParams: {
      suppressCount: true
    },
    maxWidth: 5,
    colSpan: (params: ColSpanParams) => {
      if (params.data) {
        return 1;
      } else {
        return 5;
      }
    }
  };

  constructor(private onPremService: OnPremStorageService, private cloudStorageService: CloudStorageService,
    private siteParmService: SiteParameterService) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
    this.getChannelProductLinks();
  }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getListColumnDefs();
    this.getRateLists();
    $('#NewBusinessList').addClass('tab-pane active');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.getListColumnDefs();
  }

  getListColumnDefs(): void {
    if (this.innerWidth >= this.screenWidthForLargeDevice) {
      this.ratesDeferredNBRatesColumnDefs = [
        { field: 'series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 300, resizable: true },
        { field: 'guaranteePeriod', headerName: 'Guarantee Period', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', resizable: true },
        { cellRenderer: rateLBLGetter, headerName: 'LBL\nNB Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: rateCLICGetter, headerName: 'CLIC\nNB Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesImmediateNBRatesColumnDefs = [
        { field: 'series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 300, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'LBL\nMonthly Income*', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'CLIC\nMonthly Income*', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesDeferredRenewalRatesColumnDefs = [
        { field: 'series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 300, resizable: true },
        { field: 'guaranteePeriod', headerName: 'Guarantee Period', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', resizable: true },
        { valueGetter: rateLBLGetter, headerName: 'LBL\nRen. Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { valueGetter: rateCLICGetter, headerName: 'CLIC\nRen. Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesImmediateRenewalRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 300, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'Monthly Income LBL*', maxWidth: 150, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'Monthly Income CLIC*', maxWidth: 150, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.fixedIndexRatesColumnDefs = [
        { field: 'product', rowGroup: true, hide: true },
        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 300, resizable: true },
        { field: 'guaranteePeriod', headerName: 'Guarantee Period', minWidth: 175, cellClass: 'text-center', maxWidth: 150, resizable: true },
        { field: 'rateLBL', headerName: 'LBL\nNB Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'rateCLICO', headerName: 'CLIC\nNB Interest Rate', maxWidth: 150, headerClass: "text-center", cellClass: 'text-center', tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
    }

    else if (this.innerWidth < this.screenWidthForLargeDevice && this.innerWidth > this.screenWidthForSmallDevice) {
      this.ratesDeferredNBRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 275, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'LBL\nGuar Per / NB Int Rate', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'CLIC\nGuar Per / NB Int Rate', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];

      this.ratesImmediateNBRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 275, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'LBL\nMonthly Income*', maxWidth: 150, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'CLIC\nMonthly Income*', maxWidth: 150, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesDeferredRenewalRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 275, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'Guar Per /\nRen. Int Rate LBL', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'Guar Per /\nRen. Int Rate CLIC', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesImmediateRenewalRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 275, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'Monthly Income* LBL', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'Monthly Income* CLIC', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.fixedIndexRatesColumnDefs = [
        { field: 'product', rowGroup: true, hide: true },
        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 275, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'LBL\nGuar Per / NB Int Rate', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'CLIC\nGuar Per / NB Int Rate', maxWidth: 175, headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
    }

    else {
      this.ratesDeferredNBRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 150, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'LBL\nGuar Per / NB Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'CLIC\nGuar Per / NB Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesImmediateNBRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 150, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'LBL\nMonthly Income*', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'CLIC\nMonthly Income*', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesDeferredRenewalRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Product', minWidth: 150, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'LBL\nGuar Per / Ren Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'CLIC\nGuar Per / Ren Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.ratesImmediateRenewalRatesColumnDefs = [
        { field: 'series', headerName: 'Series', rowGroup: true, hide: true },
        { field: 'product', headerName: 'Income Duration', minWidth: 150, resizable: true },
        { field: 'monthlyIncomeFactorLBL', headerName: 'LBL\nMonthly Income*', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { field: 'monthlyIncomeFactorCLICO', headerName: 'CLIC\nMonthly Income*', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
      this.fixedIndexRatesColumnDefs = [
        { field: 'product', rowGroup: true, hide: true },
        { field: 'rateTypeDesc', headerName: 'Product', minWidth: 150, resizable: true },
        { cellRenderer: periodAndRateLBLGetter, headerName: 'LBL\nGuar Per / NB Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateLBL); }, resizable: true },
        { cellRenderer: periodAndRateCLICGetter, headerName: 'CLIC\nGuar Per / NB Int Rate', headerClass: "text-center", cellClass: 'cellCenter', autoHeight: true, tooltipValueGetter: (params: any) => { return this.getRateTooltip(params.data.rateStartDateCLICO); }, resizable: true }
      ];
    }
  }

  getRateLists(): void {
    let rateType = 'NB';
    this.onPremService.getAnnuityDeferredRates(this.channelId, rateType, false)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessDeferredRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityImmediateRates(this.channelId, rateType, false)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessImmediateRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityFixedIndexRates(this.channelId, rateType, false)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessFixedIndexRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityDeferredRates(this.channelId, rateType, true)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessCADeferredRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityImmediateRates(this.channelId, rateType, true)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessCAImmediateRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityFixedIndexRates(this.channelId, rateType, true)
      .pipe(take(1))
      .subscribe(rates => {
        this.newBusinessCAFixedIndexRatesRowData = rates.data;
      });
    rateType = 'RENEW';
    this.onPremService.getAnnuityDeferredRates(this.channelId, rateType, false)
      .pipe(take(1))
      .subscribe(rates => {
        this.renewalDeferredRatesRowData = rates.data;
      });
    this.onPremService.getAnnuityImmediateRates(this.channelId, rateType, false)
      .pipe(take(1))
      .subscribe(rates => {
        this.renewalImmediateRatesRowData = rates.data;
      });
  }

  getChannelProductLinks(): void {
    this.cloudStorageService.getAnonymousProductsByChannel(this.channelId)
      .pipe(take(1))
      .subscribe(productList => {
        this.channelProduct = productList;
      });
  }

  getRateTooltip(rateStartDate: string): string {
    let returnValue = rateStartDate != undefined ? 'Effective as of ' + rateStartDate : '';
    return returnValue;
  }

  onClickTabSelect(link: string) {
    $('#listMenu').find("*").removeClass("router-link-exact-active");
    $(link).addClass('router-link-exact-active');

    switch (link) {

      case '#NewCABusinessList-tab':
        this.gridApi5.sizeColumnsToFit();
        this.gridApi6.sizeColumnsToFit();
        this.gridApi7.sizeColumnsToFit();
        break;
      case '#RenewalList-tab':
        this.gridApi3.sizeColumnsToFit();
        this.gridApi4.sizeColumnsToFit();
        break;
      default:
        this.gridApi.sizeColumnsToFit();
        this.gridApi1.sizeColumnsToFit();
        this.gridApi2.sizeColumnsToFit();
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onGridReady1(params: any) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  onGridReady2(params: any) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }
  onGridReady3(params: any) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  }
  onGridReady4(params: any) {
    this.gridApi4 = params.api;
    this.gridColumnApi4 = params.columnApi;
  }
  onGridReady5(params: any) {
    this.gridApi5 = params.api;
    this.gridColumnApi5 = params.columnApi;
  }
  onGridReady6(params: any) {
    this.gridApi6 = params.api;
    this.gridColumnApi6 = params.columnApi;
  }
  onGridReady7(params: any) {
    this.gridApi7 = params.api;
    this.gridColumnApi7 = params.columnApi;
  }
}

function seriesAndProductGetter(params: any) {
  return params.data.product + ' - ' + params.data.series;
}

function rateLBLGetter(params: any) {
  let rateValue = "";
  if (params.data.rateLBL !== params.data.rateFirstYearLBL) {
    rateValue = params.data.rateFirstYearLBL + ' (FY)<br/>' + params.data.rateLBL + ' (Base) ';
  }
  else {
    rateValue = params.data.rateLBL;
  }

  return rateValue;
}

function rateCLICGetter(params: any) {
  let rateValue = "";
  if (params.data.rateCLICO !== params.data.rateFirstYearCLICO) {
    rateValue = params.data.rateFirstYearCLICO + ' (FY)<br/>' + params.data.rateCLICO + ' (Base) ';
  }
  else {
    rateValue = params.data.rateCLICO;
  }

  return rateValue;
}

function periodAndRateLBLGetter(params: any) {
  let returnValue = ""
  let rateValue = "";
  if (params.data.rateFirstYearLBL !== "" && params.data.rateLBL !== params.data.rateFirstYearLBL) {
    rateValue = params.data.rateFirstYearLBL + ' (FY)<br/>' + params.data.rateLBL + ' (Base) ';
  }
  else {
    rateValue = params.data.rateLBL;
  }

  if (params.data.guaranteePeriod === "") {
    returnValue = rateValue;
  }
  else {
    returnValue = params.data.guaranteePeriod + '<br/>' + rateValue;
  }

  return returnValue;
}

function periodAndRateCLICGetter(params: any) {
  let returnValue = ""
  let rateValue = "";
  if (params.data.rateFirstYearCLICO !== "" && params.data.rateCLICO !== params.data.rateFirstYearCLICO) {
    rateValue = params.data.rateFirstYearCLICO + ' (FY)<br/>' + params.data.rateCLICO + ' (Base) ';
  }
  else {
    rateValue = params.data.rateCLICO;
  }

  if (params.data.guaranteePeriod === "") {
    returnValue = rateValue;
  }
  else {
    returnValue = params.data.guaranteePeriod + '<br/>' + rateValue;
  }

  return returnValue;
}
